@value colors: 'assets/css/colors.module.css';
@value brand from colors;
@value textColor from colors;

.logo-container {
  height: 255px;
}
.main-title {
  font-weight: 700;
  color: #182c3d;
}
.sub-title {
  font-weight: 600;
  color: #182c3d;
  font-size: 20px;
}
.blueText {
  color: textColor;
}
.elevateCopy {
  margin-bottom: 36px;
  font-size: 18px;
}
.elevateBottomSpacer,
.elevateTopSpacer {
  height: 100px;
}
.kardiaCompleteBody {
  margin: 24px 0;
}
.kardiaCompleteFeatureList {
  line-height: 36px;
}
.eligibilityTopSpacer,
.eligibilityBottomSpacer {
  height: 90px;
}
.eligibilitySubHead {
  margin-bottom: 24px;
  color: textColor;
}
.eligibilityText {
  font-weight: 400 !important;
}
.heartHealthHeadline {
  margin-top: 58px !important;
  color: textColor;
}
.heartHealthSubHeadline {
  margin-bottom: 56px;
  font-size: 20px;
  color: textColor;
}
.list {
  padding-inline-start: 30px;
}
.list ::marker {
  color: brand;
  font-size: 30px;
  line-height: 0;
}
.heartHealthList {
  composes: list;
  margin-right: 60px;
}
.heartHealthList li {
  margin-bottom: 29px;
}
.heartHealthList li .itemTitle {
  margin-bottom: 8px;
}
.outlinedButton2 {
  float: right;
  margin-right: 60px;
}
.kardiaCompleteBanner {
  background-color: brand;
  text-align: center;
  padding: 28px;
}
.kardiaCompleteBanner h2 {
  color: white;
}
.conditionBottomSpacer {
  margin-bottom: 100px;
}
