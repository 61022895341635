.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #182C3D !important;
}

.titles {
    text-align: center;
}

.backdrop {
    position: absolute;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    top: -20px;
    bottom: -20px;
}

.registration-form {
    display: block;
    margin: 0 270px;
}

.form-row {
    height: 70px;
}

.passwordVal {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    width: 100%;
    display: block;
    margin: 25px auto;
    cursor: pointer;
    color: #174E8C;
    text-decoration: none;
}

.redBox {
    width: 100%;
    margin-left: 0.5vw;
    height: 5.5vh;
    background: #F9E8E8;
    border: 2px solid #CC3D3F;
    border-radius: 8px;
    margin-bottom: 2.5vh;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #142A39;
    padding-left: 15px
}
.enrollLink {
    font-weight: 400;
    margin-bottom: 4vh;
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
  }
  .createAccount {
    color: #174E8C;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
@media only screen and (max-width: 600px) {
    .registration-form {
        margin: 0 20px;
    }
    .enrollLink{
        line-height: 28px;
      }
      .redBox{
        font-size: 14px;
        line-height: 19px;
      }
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .registration-form {
        margin: 0 80px;
    }
    .enrollLink{
        line-height: 28px;
        margin-bottom: 1.5vh;
      }
  }