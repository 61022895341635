@value colors: 'assets/css/colors.module.css';
@value errorRed from colors;

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #182C3D !important;
}

.backdrop {
    position: absolute;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    top: -20px;
    bottom: -20px;
}

.all-fields-required {
    color: errorRed;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
}

.form-row {
    margin-top: 20px;
}

.registration-headline {
    margin-bottom: 12px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
}

.registration-subHeading {
    font-family: "DM Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
}

.registration-form {
    display: block;
    /* margin: 0 100px; */
    width: 100%;
    padding: 0 210px
}

.backdrop-container {
    position: relative;
}
@media only screen and (max-width: 600px) {
    .registration-form {
        padding: inherit;
        margin: -40px auto;
    }
    /* .backdropContainer {
        margin-top: 40px;
    } */
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .registration-form {
        padding: inherit;
    }
  }