@value colors: 'assets/css/colors.module.css';
@value brand from colors;
@value breakpoints: 'assets/css/breakpoints.module.css';
@value sm from breakpoints;

.brand-color {
  color: brand;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  color: #182c3d !important;
}

.confirmation {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.sub-headline {
  font-weight: 700;
  margin-top: 20px;
}

.completion-message {
  text-align: center;
}

.invite-dependent-button-section {
  text-align: center;
  margin-top: 15px;
}

.qrCode {
  height: 310px;
  width: 310px;
  margin-bottom: 32px;
}

.accountCreated{
  color: var(--text-primary, #182C3D);
  text-align: center;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-bottom: 8px;
}

.emailNote{
  color: var(--text-primary, #182C3D);
  text-align: center;
  font-family: "DM Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.nextStepsHeading{
  color: var(--default-default-filldark, #174E8C);
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-top: 80px;
}
.nextStepsContainer{
  display: flex;
  align-items: center;
  padding: 0px 40px;
}
.nextSteps{
  color: var(--text-primary, #182C3D);
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.15px;
  margin: 8px 0;
  padding-left: 7px;
}

.store {
  display: flex;
  flex-direction: row;
  margin: 35px 0;
  column-gap: 20px;
  margin-left: 48px;
}
.success-message {
  font-size: 18px;
  text-align: left;
  margin: 25px auto;
  max-width: 580px;
  font-weight: 700;
  font-family: "DM Sans";
  line-height: normal;
}
.add-dependent-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.another-dependent-container {
  display: inline-block;
  margin-left: 20px;
}

.another-dependent-button {
  /* margin-left: 20px; */
}

.roundCircle {
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background-color: #6387c5;
  margin-right: 10px;
}

.code-and-steps {
  padding-left: 100px;
  display: flex;
  flex-wrap: wrap;
}

.textCircleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem auto;
  font-family: "DM Sans", sans-serif;
}

.your-code {
  composes: brand-color;
  margin-top: 125px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.next-steps {
  composes: brand-color;
  margin-top: 100px;
  text-transform: capitalize;
  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  margin-left: -36px;
}

.step1 {
  margin-top: 20px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #142a39;
}

.step2 {
  margin-top: 24px;
}
.subText {
  font-size: 17px;
  font-family: "DM Sans", sans-serif;
}

.enrollment_header {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: normal;
  margin-top: 15px;
  color: #142a39;
  letter-spacing: 0.25px;
}

@media only screen and (max-width: sm) {
  .completion-message {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 678px) {
  .enrollment_header {
    font-size: 25px;
    line-height: 28px;
  }
  .next-steps {
    margin-top: 30px;
    font-size: 30px;
    margin-left: -20px;
  }
  .textCircleDiv {
    margin: 1.7rem auto;
  }
  .subText {
    font-size: 15px;
    font-family: "DM Sans", sans-serif;
  }
  .enrollment_header {
    font-size: 20px;
  }
  .success-message {
    font-size: 16px;
  }
  .store {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
    row-gap: 10px;
  }
  .qrCode {
    display: none;
  }
  .qrText {
    display: none;
  }
}

@media (min-width: 678px) and (max-width: 1000px) {
  .enrollment_header {
    font-size: 22px;
    line-height: 28px;
  }
  .next-steps {
    margin-top: 30px;
  }
  .textCircleDiv {
    margin: 1.7rem auto;
  }
  .completion-message {
    padding-left: 60px;
  }
  .code-and-steps {
    padding-left: 60px;
  }
}
@media (max-width: 450px) {
  .code-and-steps {
    padding-left: 40px;
  }
}
