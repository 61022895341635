 .stepperStyle {
   width: 60%;
   margin: auto;
 }

 .titleEnrollText {
   margin: 3.9vh auto;
 }

 .cvsTitleEnrolltext {
   margin: 4vh auto;
   font-family: 'DM Sans', sans-serif;
 }

 .subheadline {
   font-weight: 500;
   font-size: 19px;
   font-family: 'DM Sans', sans-serif;
   margin-top: 15px;
 }
.titleMessage{
  color: #182C3D;
  margin-bottom: 16px;
  font-size: 36px;
  font-family: 'DM Sans', sans-serif;
}
.titleNote {
  font-family: 'DM Sans', sans-serif;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
 @media only screen and (max-width: 1000) {
   .stepperStyle {
     width: 100%;
   }
   .titleMessage{
    font-size: 30px;
    line-height: 29px;
   }
   .subheadline {
    line-height: 10px;
  }
 }

 @media only screen and (max-width: 500px) {
   .stepperStyle {
     width: 100%;
   }
   .titleMessage{
    font-size: 30px;
    line-height: 29px;
   }
   .subheadline {
  line-height: 27px;
  margin-bottom: 3px;
  }
 }