

.NotFoundPage {
  text-align: center;
  background-image: url('../../assets/img/404-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  height:100vh
}
.NotFoundPage h1 {
  font-size: 170px;
  font-weight: 800;
  margin-bottom: 50px;
  padding-top: 150px;
}
.NotFoundPage h2 {
  font-size: 32px;
  /* font-family: mainFontFamily; */
  margin-bottom: 14px;
  font-weight: bold;
}
.NotFoundPage p {
  font-size: 20px;
  line-height: 40px;
}
.dashboardBtn {
  /* composes: submit; */
  margin: 27px auto 0;
  padding: 11px 23px;
}
.dashboardBtn svg {
  fill: white;
  height: 20px;
  width: 20px;
  margin-top: -5px;
  margin-right: 10px;
}
