.crossStyle {
    text-align: end;
}

.warningText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    margin-left: 10px;
}

.imageTextFlex {
    display: flex;
    margin-top: -1.3rem;
}

.subTextWarning {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #142A39;
    margin: 20px auto;
}

.checkBoxHeader {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
}

.checkBoxSubHeader {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.checkBoxText {
    font-family: 'DM Sans';
    font-style: normal;
    color: #142A39;
}

.checkBoxFlex {
    margin-left: 25px;
}

.lineHR {
    border: 0.04rem solid #B4BBC0;
    margin: 20px auto;
}

.button_alignment {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 10px;
    margin: 2vh auto 1vh auto;
    width: 100%;
}
.boxStyle{
    width: 40vw 
}
@media only screen and (max-width: 600px) {
    .boxStyle{
        width: 90vw    
    }
    .subTextWarning {
        margin: 13px auto;
    }

.warningText {
    font-size: 16px;
}
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .boxStyle{
        width: 70vw  
    }
    .subTextWarning {
        margin: 13px auto;
    }
     .warningText {
        font-size: 16px;
    }
  }