.crossStyle {
    text-align: end;
}

.bodyModal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #142A39;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    text-align: center;
}

.subText {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02px;
    margin: 7px auto;
}

@media only screen and (max-width: 600px) {
    .boxStyle{
        width: 90vw    
    }
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .boxStyle{
        width: 40vw  
    }
  }