@value colors: 'assets/css/colors.module.css';
@value errorRed from colors;
@value textColor from colors;
@value breakpoints: 'assets/css/breakpoints.module.css';
@value sm from breakpoints;
@value md from breakpoints;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.titles {
  text-align: center;
}

.enrollLink {
  font-weight: 400;
  margin-bottom: 4vh;
  font-family: 'DM Sans', sans-serif;
  font-size: 17px;
}
.dependentHeader{
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: 0.25px;
  font-family: 'DM Sans';
  font-style: normal;
color: #182C3D;
}
.dependentsuccess{
text-align: center;
padding:0 50px;
}
.dependentSubHeader{
  font-family: 'DM Sans';
  font-style: normal;
  color: #182C3D;
font-weight: 500;
font-size: 19px;
line-height: 25px;
letter-spacing: 0.15px;
margin:15px auto;
}
.aLink {
  color: #174E8C;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;
}

.registration-form {
  display: block;
  margin: 0 50px;
}

.form-fields {
  margin-top: 30px;
}

.registration-headline {
  margin-bottom: 12px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  font-size: 26px;
}

.registration-error-display {
  background: #F9E8E8;
  border: 2px solid errorRed;
  border-radius: 8px;
  padding: 16px 16px 16px 68px;
  background-image: url('assets/img/error.svg');
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 35px 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  display: none;
}

.registration-error-display .error-triangle {
  width: 35px;
  height: 30px;
}

.registration-display-errors {
  display: block;
}

.registration-subtitle {
  font-size: 17px;
  font-weight: 400;
  color: #142A39;
  font-family: 'DM Sans', sans-serif;
}

.all-fields-required {
  color: errorRed;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  font-family: 'DM Sans', sans-serif;
}
.redBox {
  width: 100%;
  height: 5.5vh;
  background: #F9E8E8;
  border: 2px solid #CC3D3F;
  border-radius: 8px;
  margin-bottom: 1.5vh;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #142A39;
  padding-left: 15px
}
.asterikStyle {
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.requiredText {
  color: red;  
  margin: 10px 0;
  font-size: 14px;
}

.eligibility-intro {
  font-size: 16px;
}

.eligibility-headline {
  margin-top: 22px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #142A39;
}

.errorLine {
  font-size: 13px;
  color: rgb(204, 61, 63);
  font-weight: 100;
  font-family: 'DM Sans', sans-serif;
}

.eligibility-label {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #142A39;
}

.form-row {
  height: auto;
  margin: 10px 0;
}
.label-only{
  margin-top: 20px;
}
.no-label-input{
  margin: -10px 0 20px;
}
.form-row-dependents{
  height: 95px;
}

.titleCheckBox {
  font-size: 15px;
  font-weight: bold;
  height: 40px;
}

/* .atrialHypertensionStyle{
  margin-left: 50px;
} */
.form-consent {
  padding-top: 0px !important;
}

.add-dependent-container {
  margin-bottom: 30px;
}

.submit-button-container {
  margin-top: 20px;
}

.backdrop {
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 20px;
  top: -20px;
  bottom: -20px;
}

.backdrop-container {
  position: relative;
}

.invite-outline-button {
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #142A39;
  border-color: #142A39;
  font-family: 'DM Sans', sans-serif;
}

.atrialHypertensionStyle {
  margin-left: 35px;
}

.subLoginText {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #142A39;
  margin-top: 0.7vh;
  margin-bottom: 1vh;
}

.createAccount {
  color: #174E8C;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.gridContainer {
  color: #182C3D !important;
  font-family: 'DM Sans', sans-serif;
}
.formText{
  font-size: 17px;
  font-family:'DM Sans', sans-serif;
  font-weight: 400;
}
.checkBoxText{
  font-weight: bold;
                        font-size: 17px;
                        font-family: 'DM Sans', sans-serif;
                        margin-top: 15px;
}
@media only screen and (max-width: md) {
  .backdrop-container {
    margin-top: 40px;
  }

  .eligibility {
    margin: 0 50px;
  }

  .add-dependent-container {
    margin-top: 30px;
  }
  .enrollLink{
    line-height: 28px;
  }
}

@media only screen and (max-width: 600px) {
  .button_alignment {
    flex-direction: column;
  }
  .enrollLink{
    line-height: 28px;
    margin-bottom: 1.5vh;
  }
  .registration-form {
    margin: 0 25px;
  }
  .registration-headline {
    font-size: 19px;
  }
  .all-fields-required {
    margin-top: 7px;
  }
  .redBox{
    font-size: 14px;
    line-height: 19px;
    margin-top: 20px;
  }
  .formText{
    font-size: 15px;
    line-height: 23px;
  }
  .form-fields {
    margin-top: 15px;
  }
  .form-row {
    height: auto;
  }
  
  .checkBoxText{
                          font-size: 15px;
                          margin-top: inherit;
  }
  .atrialHypertensionStyle {
    margin-left: 25px;
  }
}