@value colors: 'assets/css/colors.module.css';
@value errorRed from colors;

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #182C3D !important;
}

.titles {
    text-align: center;
}

.backdropContainer {
    position: relative;
}

.backdrop {
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    top: -20px;
    bottom: -20px;
}

.buttonWrapper {
    margin: 2rem auto auto auto;
}

.askQuestionVec {
    display: flex;
    align-items: center;
    color: #142A39;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    /* margin-bottom: 7px; */
}

.askQuestionSubText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #142A39;
}

.askQuestion {
    width: 100%;
    color: #174E8C;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    margin-top: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
}

.registration-form {
    display: block;
    margin: 0 270px;
}

.registration-headline {
    margin-bottom: 12px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
}

.all-fields-required {
    color: errorRed;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
}

.form-fields {
    margin-top: 30px;
}

.form-row {
    height: auto;
    margin: 10px 0;
}
.label-only{
    margin-top: 20px;
}
.no-label-input{
  margin: -10px 0 20px;
}
@media only screen and (max-width: 600px) {
    .registration-form {
        margin: 0 20px;
    }
    /* .backdropContainer {
        margin-top: 40px;
    } */
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .registration-form {
        margin: 0 80px;
    }
  }