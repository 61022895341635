@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Poppins';
}

/* @value brand: rgb(6, 127, 111); */

html,
body {
  min-height: 100vh;
  background-color: white;
}
a {
  color: #182C3D;
}
a:visited {
  color: #174E8C;
}
a:active {
  color: #174E8C;
}
.css_product-name__1NzqF {
  font-weight: 700;
  color: #174E8C;
}
.css_support-link__3qSM1 {
  margin-right: 15px;
  font-size: 18px;
  display: block;
}
.css_phone__2h-MH {
  color: black;
  text-decoration: none;
}

.buttons_enroll-button__15kXN {
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
}

.buttons_enroll-button__15kXN button {
  font-weight: 700;
  font-size: 15px;
  font-family: 'DM Sans', sans-serif;
  border-color: #174E8C;
}

.buttons_enroll__1L_dH {
  font-family: 'DM Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
}
.enrollment_container__bfbBM {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  color: #182C3D !important;
}

.enrollment_textFont__2ZXy6 {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: undefined;
}

.enrollment_button_alignment__22J7S {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 2vh auto;
}

.enrollment_stepperStyle__v-Nsb {
  width: 40%;
  margin: auto;
}

.enrollment_textStyle__3smYw {
  font-family: 'DM Sans', sans-serif;
  color: undefined;
  font-size: 19px;
}

.enrollment_enrollAlignTexts__3BRUM {
  margin: 11vh 0;
}

.enrollment_enrollTextFirst__fQ2gu {
  margin: 4vh 0;
}

@media only screen and (max-width: 500px) {
  .enrollment_stepperStyle__v-Nsb {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .enrollment_button_alignment__22J7S {
    /* flex-direction: column; */
    grid-gap: 12px;
    gap: 12px
  }
}
 .stepperMessage_stepperStyle__3sfAk {
   width: 60%;
   margin: auto;
 }

 .stepperMessage_titleEnrollText__16QP5 {
   margin: 3.9vh auto;
 }

 .stepperMessage_cvsTitleEnrolltext__3VX7K {
   margin: 4vh auto;
   font-family: 'DM Sans', sans-serif;
 }

 .stepperMessage_subheadline__2QG4T {
   font-weight: 500;
   font-size: 19px;
   font-family: 'DM Sans', sans-serif;
   margin-top: 15px;
 }
.stepperMessage_titleMessage__3kknI{
  color: #182C3D;
  margin-bottom: 16px;
  font-size: 36px;
  font-family: 'DM Sans', sans-serif;
}
.stepperMessage_titleNote__16R8E {
  font-family: 'DM Sans', sans-serif;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
 @media only screen and (max-width: 1000) {
   .stepperMessage_stepperStyle__3sfAk {
     width: 100%;
   }
   .stepperMessage_titleMessage__3kknI{
    font-size: 30px;
    line-height: 29px;
   }
   .stepperMessage_subheadline__2QG4T {
    line-height: 10px;
  }
 }

 @media only screen and (max-width: 500px) {
   .stepperMessage_stepperStyle__3sfAk {
     width: 100%;
   }
   .stepperMessage_titleMessage__3kknI{
    font-size: 30px;
    line-height: 29px;
   }
   .stepperMessage_subheadline__2QG4T {
  line-height: 27px;
  margin-bottom: 3px;
  }
 }


.NotFoundPage_NotFoundPage__1fhjq {
  text-align: center;
  background-image: url(/static/media/404-bg.6ac17a9a.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  height:100vh
}
.NotFoundPage_NotFoundPage__1fhjq h1 {
  font-size: 170px;
  font-weight: 800;
  margin-bottom: 50px;
  padding-top: 150px;
}
.NotFoundPage_NotFoundPage__1fhjq h2 {
  font-size: 32px;
  /* font-family: mainFontFamily; */
  margin-bottom: 14px;
  font-weight: bold;
}
.NotFoundPage_NotFoundPage__1fhjq p {
  font-size: 20px;
  line-height: 40px;
}
.NotFoundPage_dashboardBtn__3_IBS {
  /* composes: submit; */
  margin: 27px auto 0;
  padding: 11px 23px;
}
.NotFoundPage_dashboardBtn__3_IBS svg {
  fill: white;
  height: 20px;
  width: 20px;
  margin-top: -5px;
  margin-right: 10px;
}


.confirmation_brand-color__nuhu1 {
  color: #174E8C;
}

.confirmation_container__L9Bvh {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  color: #182c3d !important;
}

.confirmation_confirmation__s2or6 {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.confirmation_sub-headline__23v4l {
  font-weight: 700;
  margin-top: 20px;
}

.confirmation_completion-message__hHoJY {
  text-align: center;
}

.confirmation_invite-dependent-button-section__1kUi0 {
  text-align: center;
  margin-top: 15px;
}

.confirmation_qrCode__1bKwq {
  height: 310px;
  width: 310px;
  margin-bottom: 32px;
}

.confirmation_accountCreated__2Rr0e{
  color: #182C3D;
  color: var(--text-primary, #182C3D);
  text-align: center;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-bottom: 8px;
}

.confirmation_emailNote__3P5To{
  color: #182C3D;
  color: var(--text-primary, #182C3D);
  text-align: center;
  font-family: "DM Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.confirmation_nextStepsHeading__2tAtn{
  color: #174E8C;
  color: var(--default-default-filldark, #174E8C);
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-top: 80px;
}
.confirmation_nextStepsContainer__138Xd{
  display: flex;
  align-items: center;
  padding: 0px 40px;
}
.confirmation_nextSteps__18crq{
  color: #182C3D;
  color: var(--text-primary, #182C3D);
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.15px;
  margin: 8px 0;
  padding-left: 7px;
}

.confirmation_store__HUdUD {
  display: flex;
  flex-direction: row;
  margin: 35px 0;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin-left: 48px;
}
.confirmation_success-message__ujcjC {
  font-size: 18px;
  text-align: left;
  margin: 25px auto;
  max-width: 580px;
  font-weight: 700;
  font-family: "DM Sans";
  line-height: normal;
}
.confirmation_add-dependent-container__1dhvR {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.confirmation_another-dependent-container__sUL0y {
  display: inline-block;
  margin-left: 20px;
}

.confirmation_another-dependent-button__2S66L {
  /* margin-left: 20px; */
}

.confirmation_roundCircle__2ctnO {
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background-color: #6387c5;
  margin-right: 10px;
}

.confirmation_code-and-steps__1YifD {
  padding-left: 100px;
  display: flex;
  flex-wrap: wrap;
}

.confirmation_textCircleDiv__u0tqP {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem auto;
  font-family: "DM Sans", sans-serif;
}

.confirmation_your-code__M6GPe {
  margin-top: 125px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.confirmation_next-steps__2GraJ {
  margin-top: 100px;
  text-transform: capitalize;
  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  margin-left: -36px;
}

.confirmation_step1__16wr1 {
  margin-top: 20px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #142a39;
}

.confirmation_step2__181Wb {
  margin-top: 24px;
}
.confirmation_subText__TvZli {
  font-size: 17px;
  font-family: "DM Sans", sans-serif;
}

.confirmation_enrollment_header__3iaMF {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: normal;
  margin-top: 15px;
  color: #142a39;
  letter-spacing: 0.25px;
}

@media only screen and (max-width: 600px) {
  .confirmation_completion-message__hHoJY {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 678px) {
  .confirmation_enrollment_header__3iaMF {
    font-size: 25px;
    line-height: 28px;
  }
  .confirmation_next-steps__2GraJ {
    margin-top: 30px;
    font-size: 30px;
    margin-left: -20px;
  }
  .confirmation_textCircleDiv__u0tqP {
    margin: 1.7rem auto;
  }
  .confirmation_subText__TvZli {
    font-size: 15px;
    font-family: "DM Sans", sans-serif;
  }
  .confirmation_enrollment_header__3iaMF {
    font-size: 20px;
  }
  .confirmation_success-message__ujcjC {
    font-size: 16px;
  }
  .confirmation_store__HUdUD {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .confirmation_qrCode__1bKwq {
    display: none;
  }
  .confirmation_qrText__3wvzx {
    display: none;
  }
}

@media (min-width: 678px) and (max-width: 1000px) {
  .confirmation_enrollment_header__3iaMF {
    font-size: 22px;
    line-height: 28px;
  }
  .confirmation_next-steps__2GraJ {
    margin-top: 30px;
  }
  .confirmation_textCircleDiv__u0tqP {
    margin: 1.7rem auto;
  }
  .confirmation_completion-message__hHoJY {
    padding-left: 60px;
  }
  .confirmation_code-and-steps__1YifD {
    padding-left: 60px;
  }
}
@media (max-width: 450px) {
  .confirmation_code-and-steps__1YifD {
    padding-left: 40px;
  }
}

.hero_logo-container__1SVUz {
  height: 255px;
}
.hero_main-title__1N4Z6 {
  font-weight: 700;
  color: #182c3d;
}
.hero_sub-title__2iG0U {
  font-weight: 600;
  color: #182c3d;
  font-size: 20px;
}
.hero_blueText__2jGee {
  color: #182C3D;
}
.hero_elevateCopy__3PZ4D {
  margin-bottom: 36px;
  font-size: 18px;
}
.hero_elevateBottomSpacer__VI3yB,
.hero_elevateTopSpacer__1B1g6 {
  height: 100px;
}
.hero_kardiaCompleteBody__3kOQP {
  margin: 24px 0;
}
.hero_kardiaCompleteFeatureList__39m3K {
  line-height: 36px;
}
.hero_eligibilityTopSpacer__19xfx,
.hero_eligibilityBottomSpacer__26SIS {
  height: 90px;
}
.hero_eligibilitySubHead__1BXAV {
  margin-bottom: 24px;
  color: #182C3D;
}
.hero_eligibilityText__1J4FC {
  font-weight: 400 !important;
}
.hero_heartHealthHeadline__1LB9r {
  margin-top: 58px !important;
  color: #182C3D;
}
.hero_heartHealthSubHeadline__3FJlo {
  margin-bottom: 56px;
  font-size: 20px;
  color: #182C3D;
}
.hero_list__2ntx7 {
  -webkit-padding-start: 30px;
          padding-inline-start: 30px;
}
.hero_list__2ntx7 ::marker {
  color: #174E8C;
  font-size: 30px;
  line-height: 0;
}
.hero_heartHealthList__29iaS {
  margin-right: 60px;
}
.hero_heartHealthList__29iaS li {
  margin-bottom: 29px;
}
.hero_heartHealthList__29iaS li .hero_itemTitle__2E2Lq {
  margin-bottom: 8px;
}
.hero_outlinedButton2__1JNo5 {
  float: right;
  margin-right: 60px;
}
.hero_kardiaCompleteBanner__nx7wE {
  background-color: #174E8C;
  text-align: center;
  padding: 28px;
}
.hero_kardiaCompleteBanner__nx7wE h2 {
  color: white;
}
.hero_conditionBottomSpacer__1puaL {
  margin-bottom: 100px;
}

.register_titles__3UivG {
  text-align: center;
}

.register_enrollLink__hFKLq {
  font-weight: 400;
  margin-bottom: 4vh;
  font-family: 'DM Sans', sans-serif;
  font-size: 17px;
}
.register_dependentHeader__3vLPq{
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: 0.25px;
  font-family: 'DM Sans';
  font-style: normal;
color: #182C3D;
}
.register_dependentsuccess__2Cvwj{
text-align: center;
padding:0 50px;
}
.register_dependentSubHeader__eADd-{
  font-family: 'DM Sans';
  font-style: normal;
  color: #182C3D;
font-weight: 500;
font-size: 19px;
line-height: 25px;
letter-spacing: 0.15px;
margin:15px auto;
}
.register_aLink__17-AR {
  color: #174E8C;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;
}

.register_registration-form__1FtqI {
  display: block;
  margin: 0 50px;
}

.register_form-fields__16Iua {
  margin-top: 30px;
}

.register_registration-headline__3OTXO {
  margin-bottom: 12px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  font-size: 26px;
}

.register_registration-error-display__dr1Nr {
  background: #F9E8E8;
  border: 2px solid rgb(204, 61, 63);
  border-radius: 8px;
  padding: 16px 16px 16px 68px;
  background-image: url(/static/media/error.3d96d4f2.svg);
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 35px 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  display: none;
}

.register_registration-error-display__dr1Nr .register_error-triangle__36y7l {
  width: 35px;
  height: 30px;
}

.register_registration-display-errors__2jAVB {
  display: block;
}

.register_registration-subtitle__1rFi6 {
  font-size: 17px;
  font-weight: 400;
  color: #142A39;
  font-family: 'DM Sans', sans-serif;
}

.register_all-fields-required__15bpc {
  color: rgb(204, 61, 63);
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  font-family: 'DM Sans', sans-serif;
}
.register_redBox__alKWb {
  width: 100%;
  height: 5.5vh;
  background: #F9E8E8;
  border: 2px solid #CC3D3F;
  border-radius: 8px;
  margin-bottom: 1.5vh;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #142A39;
  padding-left: 15px
}
.register_asterikStyle__3zOAv {
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.register_requiredText__1ariA {
  color: red;  
  margin: 10px 0;
  font-size: 14px;
}

.register_eligibility-intro__17pGF {
  font-size: 16px;
}

.register_eligibility-headline__OZ43- {
  margin-top: 22px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #142A39;
}

.register_errorLine__3MYNk {
  font-size: 13px;
  color: rgb(204, 61, 63);
  font-weight: 100;
  font-family: 'DM Sans', sans-serif;
}

.register_eligibility-label___MgN_ {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #142A39;
}

.register_form-row__3VACY {
  height: auto;
  margin: 10px 0;
}
.register_label-only__33Y_6{
  margin-top: 20px;
}
.register_no-label-input__1gdhx{
  margin: -10px 0 20px;
}
.register_form-row-dependents__1BNgh{
  height: 95px;
}

.register_titleCheckBox__3CHcH {
  font-size: 15px;
  font-weight: bold;
  height: 40px;
}

/* .atrialHypertensionStyle{
  margin-left: 50px;
} */
.register_form-consent__2rDYn {
  padding-top: 0px !important;
}

.register_add-dependent-container__2W41M {
  margin-bottom: 30px;
}

.register_submit-button-container__2nbDL {
  margin-top: 20px;
}

.register_backdrop__2DELi {
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 20px;
  top: -20px;
  bottom: -20px;
}

.register_backdrop-container__A-aES {
  position: relative;
}

.register_invite-outline-button__RX8pm {
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #142A39;
  border-color: #142A39;
  font-family: 'DM Sans', sans-serif;
}

.register_atrialHypertensionStyle__1fNVN {
  margin-left: 35px;
}

.register_subLoginText__3FEZi {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #142A39;
  margin-top: 0.7vh;
  margin-bottom: 1vh;
}

.register_createAccount__1-lJU {
  color: #174E8C;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.register_gridContainer__rjTtM {
  color: #182C3D !important;
  font-family: 'DM Sans', sans-serif;
}
.register_formText__6e1u3{
  font-size: 17px;
  font-family:'DM Sans', sans-serif;
  font-weight: 400;
}
.register_checkBoxText__SW_pM{
  font-weight: bold;
                        font-size: 17px;
                        font-family: 'DM Sans', sans-serif;
                        margin-top: 15px;
}
@media only screen and (max-width: 900px) {
  .register_backdrop-container__A-aES {
    margin-top: 40px;
  }

  .register_eligibility__23H29 {
    margin: 0 50px;
  }

  .register_add-dependent-container__2W41M {
    margin-top: 30px;
  }
  .register_enrollLink__hFKLq{
    line-height: 28px;
  }
}

@media only screen and (max-width: 600px) {
  .register_button_alignment__3fwhn {
    flex-direction: column;
  }
  .register_enrollLink__hFKLq{
    line-height: 28px;
    margin-bottom: 1.5vh;
  }
  .register_registration-form__1FtqI {
    margin: 0 25px;
  }
  .register_registration-headline__3OTXO {
    font-size: 19px;
  }
  .register_all-fields-required__15bpc {
    margin-top: 7px;
  }
  .register_redBox__alKWb{
    font-size: 14px;
    line-height: 19px;
    margin-top: 20px;
  }
  .register_formText__6e1u3{
    font-size: 15px;
    line-height: 23px;
  }
  .register_form-fields__16Iua {
    margin-top: 15px;
  }
  .register_form-row__3VACY {
    height: auto;
  }
  
  .register_checkBoxText__SW_pM{
                          font-size: 15px;
                          margin-top: inherit;
  }
  .register_atrialHypertensionStyle__1fNVN {
    margin-left: 25px;
  }
}
.controls_text-field__2sxJd {
  line-height: 34px;
  border: 1px solid rgba(20, 42, 57, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding-left: 6px;
  padding-right: 20px;
  outline-color: rgb(186, 191, 189);
  font-family: "DM Sans", sans-serif;
}

.controls_invalid__q-Z0Q .controls_text-field__2sxJd {
  border-color: rgb(204, 61, 63);
  background-image: url(/static/media/error.3d96d4f2.svg);
  background-repeat: no-repeat;
  background-position: right 5px center;
  outline-color: rgb(204, 61, 63);
}
.controls_invalidPass__3Q1jq .controls_text-field__2sxJd{
  outline-color: rgb(204, 61, 63);
  border-color: rgb(204, 61, 63);
}
.controls_field-label__U6vwu {
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  color: #142a39;
}

.controls_passwordEye__10oxb {
  display: flex;
}

.controls_asterikStyle__30c1d {
  color: red;
  margin-left: 10px;
  margin-bottom: 5px;
}
.controls_controls_select-field__2pBUg__1gYZf{
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnAx4DFBefyDctAAAAhklEQVRIx2NgGAUjHTCKr/Qt7L5SXs6Qx3iXcUdHB70s/i/K4MfY29DACBOgl0NgFr+6siml+HljIyO6Alo5BN1imDgjLg3Ucgguiwk6gFKHELKYaAeQ6hBiLSYbwBwiLu7n19Pz/z+MFtPxm9MrWV9PdQtxOuS/7//u/w0NMJpuFo+CYQcABZZtCvtusv4AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDMtMzBUMDM6MjA6MjMrMDA6MDBTQ95wAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAzLTMwVDAzOjIwOjIzKzAwOjAwIh5mzAAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wMy0zMFQwMzoyMDoyMyswMDowMHULRxMAAAAASUVORK5CYII=) no-repeat right #fff !important;
}
.controls_noInputText__2Op58 {
  color: #182C3D;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.controls_field-label-checkbox__40unp {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #142a39;
  margin-left: 5px;
}

.controls_field-label-radio__1uNAo {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #142a39;
  margin-left: 5px;
}

.controls_checkbox-field__H-7qh {
  width: 15px;
  height: 15px;
}

/* .checkbox-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
} */
.controls_email-field-label__2UlFQ {
  font-family: "DM Sans", sans-serif;
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.controls_password-field-label__1znGV {
  font-family: "DM Sans", sans-serif;
}

.controls_eyeStyle__1AyrU {
  margin-left: -30px;
  cursor: pointer;
  margin-top: 11px;
}

.controls_remove__2mH01 {
  color: #174E8C;
  /* position: absolute; */
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* top: 1px;
  right: 0px; */
  text-transform: capitalize;
  padding-right: 0;
}

.controls_error__1xahO {
  color: rgb(204, 61, 63);
  display: none;
  font-size: 14px;
  text-transform: none;
  font-family: "DM Sans", sans-serif;
}
.controls_passValStyle__3EL9d{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 20px;
letter-spacing: 0.25px;

/* default/default-text */

color: #182C3D;
}
.controls_invalid__q-Z0Q .controls_error__1xahO {
  display: block;
}
.controls_invalidPass__3Q1jq .controls_error__1xahO{
  display: block;
}
.controls_select-field__2pBUg {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 38px;
  font-family: "DM Sans", sans-serif;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABUSURBVHgB7c2xDQAhDAPA/K/167DEV4SKJdgGMRcIiYICJaGgwtfasokA4LhHCl3MXOn1ykBI/8dCLpNOtPHR0a1OLOOjZzOfWMe39RMXCxMAXKYByR0cY86Vad4AAAAASUVORK5CYII=) no-repeat right #fff;
  background-position: right 7px top 7px;
  outline-color: rgb(186, 191, 189);
}
.controls_charStyle__3bm77{
  display: flex;
  align-items: center;
  margin:4px auto auto 20px
}
select::-ms-expand {
  display: none;
}

.controls_invalid__q-Z0Q .controls_select-field__2pBUg {
  background-position: right 10px center;
}

.controls_formSubText__2WTXi {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #182c3d;
  margin: 8px auto;
  text-transform: none !important;
}

@media screen and (max-width: 400px) {
  .controls_email-field-label__2UlFQ {
    display: inline;
  }
  .controls_formSubText__2WTXi {
    font-size: 13px;
    margin: 4px auto;
  }
  .controls_remove__2mH01 {
    float: right;
  }
}
.errorModal_crossStyle__2aYJ1 {
    text-align: end;
}

.errorModal_modalStyle__3cgbS {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto 2rem;
    font-family: 'DM Sans', sans-serif;
}
.account_container__2v9ID {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #182C3D !important;
}

.account_titles__2jsBJ {
    text-align: center;
}

.account_backdropContainer__1EH5z {
    position: relative;
}

.account_backdrop__2qDmr {
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    top: -20px;
    bottom: -20px;
}

.account_buttonWrapper__3VlOn {
    margin: 2rem auto auto auto;
}

.account_askQuestionVec__2fSIO {
    display: flex;
    align-items: center;
    color: #142A39;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    /* margin-bottom: 7px; */
}

.account_askQuestionSubText__hlhaR {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #142A39;
}

.account_askQuestion__2lySp {
    width: 100%;
    color: #174E8C;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    margin-top: 5px;
    flex-direction: row-reverse;
    cursor: pointer;
}

.account_registration-form__Ocjrk {
    display: block;
    margin: 0 270px;
}

.account_registration-headline__3AGG2 {
    margin-bottom: 12px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
}

.account_all-fields-required__2fgET {
    color: rgb(204, 61, 63);
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
}

.account_form-fields__mYVge {
    margin-top: 30px;
}

.account_form-row__UUx6u {
    height: auto;
    margin: 10px 0;
}
.account_label-only__2IE1Y{
    margin-top: 20px;
}
.account_no-label-input__pVWiX{
  margin: -10px 0 20px;
}
@media only screen and (max-width: 600px) {
    .account_registration-form__Ocjrk {
        margin: 0 20px;
    }
    /* .backdropContainer {
        margin-top: 40px;
    } */
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .account_registration-form__Ocjrk {
        margin: 0 80px;
    }
  }
.shipping_container__IapiF {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #182C3D !important;
}

.shipping_backdrop__3_X_- {
    position: absolute;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    top: -20px;
    bottom: -20px;
}

.shipping_all-fields-required__2qROv {
    color: rgb(204, 61, 63);
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    font-family: 'DM Sans', sans-serif;
}

.shipping_form-row__sLesq {
    margin-top: 20px;
}

.shipping_registration-headline__fQkb0 {
    margin-bottom: 12px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    font-family: 'DM Sans', sans-serif;
    font-size: 26px;
}

.shipping_registration-subHeading__3dPqB {
    font-family: "DM Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
}

.shipping_registration-form__2YOIn {
    display: block;
    /* margin: 0 100px; */
    width: 100%;
    padding: 0 210px
}

.shipping_backdrop-container__3-tax {
    position: relative;
}
@media only screen and (max-width: 600px) {
    .shipping_registration-form__2YOIn {
        padding: inherit;
        margin: -40px auto;
    }
    /* .backdropContainer {
        margin-top: 40px;
    } */
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .shipping_registration-form__2YOIn {
        padding: inherit;
    }
  }
.verifyAddress_crossStyle__QiDj- {
    text-align: end;
}

.verifyAddress_warningText__2noPQ {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    margin-left: 10px;
}

.verifyAddress_imageTextFlex__2IjVo {
    display: flex;
    margin-top: -1.3rem;
}

.verifyAddress_subTextWarning__3OjD4 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #142A39;
    margin: 20px auto;
}

.verifyAddress_checkBoxHeader__25cCy {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
}

.verifyAddress_checkBoxSubHeader__1oBBy {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.verifyAddress_checkBoxText__3hllG {
    font-family: 'DM Sans';
    font-style: normal;
    color: #142A39;
}

.verifyAddress_checkBoxFlex__1cdtH {
    margin-left: 25px;
}

.verifyAddress_lineHR__x1cvU {
    border: 0.04rem solid #B4BBC0;
    margin: 20px auto;
}

.verifyAddress_button_alignment__1ohMP {
    display: flex;
    /* flex-wrap: wrap; */
    grid-gap: 10px;
    gap: 10px;
    margin: 2vh auto 1vh auto;
    width: 100%;
}
.verifyAddress_boxStyle__d1qVp{
    width: 40vw 
}
@media only screen and (max-width: 600px) {
    .verifyAddress_boxStyle__d1qVp{
        width: 90vw    
    }
    .verifyAddress_subTextWarning__3OjD4 {
        margin: 13px auto;
    }

.verifyAddress_warningText__2noPQ {
    font-size: 16px;
}
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .verifyAddress_boxStyle__d1qVp{
        width: 70vw  
    }
    .verifyAddress_subTextWarning__3OjD4 {
        margin: 13px auto;
    }
     .verifyAddress_warningText__2noPQ {
        font-size: 16px;
    }
  }

.login_container__1kt0R {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #182C3D !important;
}

.login_titles__v9WyD {
    text-align: center;
}

.login_backdrop__3bDPA {
    position: absolute;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 20px;
    top: -20px;
    bottom: -20px;
}

.login_registration-form__2yEOd {
    display: block;
    margin: 0 270px;
}

.login_form-row__26XKu {
    height: 70px;
}

.login_passwordVal__1645Y {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    width: 100%;
    display: block;
    margin: 25px auto;
    cursor: pointer;
    color: #174E8C;
    text-decoration: none;
}

.login_redBox__xRCLm {
    width: 100%;
    margin-left: 0.5vw;
    height: 5.5vh;
    background: #F9E8E8;
    border: 2px solid #CC3D3F;
    border-radius: 8px;
    margin-bottom: 2.5vh;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #142A39;
    padding-left: 15px
}
.login_enrollLink__1BVYt {
    font-weight: 400;
    margin-bottom: 4vh;
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
  }
  .login_createAccount__2t89A {
    color: #174E8C;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
@media only screen and (max-width: 600px) {
    .login_registration-form__2yEOd {
        margin: 0 20px;
    }
    .login_enrollLink__1BVYt{
        line-height: 28px;
      }
      .login_redBox__xRCLm{
        font-size: 14px;
        line-height: 19px;
      }
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .login_registration-form__2yEOd {
        margin: 0 80px;
    }
    .login_enrollLink__1BVYt{
        line-height: 28px;
        margin-bottom: 1.5vh;
      }
  }
.Qr_crossStyle__3syj5 {
    text-align: end;
}

.Qr_bodyModal__TNvti {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #142A39;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;

    text-align: center;
}

.Qr_subText__BDaVq {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02px;
    margin: 7px auto;
}
.Qr_boxStyle__1obDO{
    width: 35vw 
}
@media only screen and (max-width: 600px) {
    .Qr_boxStyle__1obDO{
        width: 90vw    
    }
    .Qr_MainText__3WMto{
       font-size: 20px;
       line-height: 24px;   
    }
    .Qr_subText__BDaVq{
        font-size: 16px;
        line-height: 22px;  
        margin-top: 12px;
    }
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .Qr_boxStyle__1obDO{
        width: 60vw  
    }
  }
.ErrorBox_crossStyle__fVVOd {
    text-align: end;
}

.ErrorBox_bodyModal__2OnOT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #142A39;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    text-align: center;
}

.ErrorBox_subText__u8WiV {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02px;
    margin: 7px auto;
}

@media only screen and (max-width: 600px) {
    .ErrorBox_boxStyle__9AZch{
        width: 90vw    
    }
  }
  
  @media (min-width: 600px) and (max-width:1000px) {
    .ErrorBox_boxStyle__9AZch{
        width: 40vw  
    }
  }
