@value colors: '../../assets/css/colors.module.css';
@value blue from colors;
@value lightBlue from colors;
@value smoke from colors;
@value brand from colors;
@value textColor from colors;

html,
body {
  min-height: 100vh;
  background-color: white;
}
a {
  color: textColor;
}
a:visited {
  color: brand;
}
a:active {
  color: brand;
}
.product-name {
  font-weight: 700;
  color: brand;
}
.support-link {
  margin-right: 15px;
  font-size: 18px;
  display: block;
}
.phone {
  color: black;
  text-decoration: none;
}
